import React from 'react';
import { Box } from 'rebass/styled-components';

function Input({ sx, inputRef, ...props }) {
  return (
    <Box
      ref={inputRef}
      as="input"
      type="text"
      variant="input"
      sx={{
        appearance: 'none',
        display: 'block',
        width: '100%',
        color: props.color || "black",
        p: 2,
        border: '0',
        borderBottom: '2px solid',
        borderColor: 'gray.300',
        borderRadius: 'default',
        mb: "1px",
        background: "white",
        transition: 'border-color 0.5s ease-in-out',

        ':focus': {
          outline: 'none',
          borderBottom: '2px solid',
          borderColor: 'primary',
          mb: 0,
        },
        "::placeholder": {
          color: props.placeholderColor || "gray.800"
        },
        ...sx
      }}
      {...props}
    />
  )
}


export default React.forwardRef((props, ref) => {
  return <Input {...props} inputRef={ref} />;
});
