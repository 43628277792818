function Arrow() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 23 9"
      width="23px"
      /*style="enable-background:new 0 0 23 9;"*/
    >
      <title>Merlet arrow</title>

      <line
        x1="18.5"
        y1="8.4"
        x2="22.5"
        y2="4.4"
        fill="none"
        stroke="#D6B985"
        strokeWidth="1"
      />
      <line
        x1="18.5"
        y1="0.4"
        x2="22.5"
        y2="4.4"
        fill="none"
        stroke="#D6B985"
        strokeWidth="1"
      />
      <line x1="0" y1="4.4" x2="23" y2="4.4" fill="none" stroke="#D6B985" />
    </svg>
  );
}
export default Arrow;
