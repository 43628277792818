import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { Box, Text } from "rebass/styled-components";
import { clamp } from "../library";
import ArrowPrev from "./svg/arrow-left";
import ArrowNext from "./svg/arrow-right";

export const Context = React.createContext();

class Carousel extends Component {
  static propTypes = {
    itemsPerPage: PropTypes.number,
    defaultActiveItem: PropTypes.number,
  };

  static defaultProps = {
    itemsPerPage: 3,
    defaultActiveItem: 0,
  };

  state = {
    activeItem: this.props.defaultActiveItem,
    slides: 0,
  };

  slidePerPage = (direction) => {
    const { itemsPerPage } = this.props;
    const { activeItem, slides } = this.state;
    const newActiveItem = activeItem + direction * itemsPerPage;

    this.setState({
      activeItem: clamp(0, slides - itemsPerPage, newActiveItem),
    });
  };

  slideToItem = (activeItem) => this.setState({ activeItem });

  setSlides = (slides) => this.setState({ slides });

  clampedIndex = (index) =>
    clamp(0, this.state.slides - this.props.itemsPerPage, index);

  render() {
    const { children, itemsPerPage, sx, ...props } = this.props;
    const { activeItem, slides } = this.state;

    return (
      <Context.Provider
        value={{
          slidePerPage: this.slidePerPage,
          slideToItem: this.slideToItem,
          setSlides: this.setSlides,
          itemsPerPage,
          activeItem,
          slides,
        }}
      >
        <Box
          {...props}
          sx={{
            position: "relative",
            ...sx,
          }}
        >
          {children}
        </Box>
      </Context.Provider>
    );
  }
}

// ! TODO Abstract Slides with one responsibility (Translate container).
export class Slides extends Component {
  static contextType = Context;

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    transition: PropTypes.string,
  };

  static defaultProps = {
    transition: "transform 0.75s ease 0s",
  };

  componentDidMount() {
    const { children } = this.props;
    this.context.setSlides(children.length);
  }

  render() {
    const { transition, children, ...props } = this.props;
    const { itemsPerPage, activeItem } = this.context;

    const calculatePosition = (itemPos) => (100 / itemsPerPage) * -itemPos;
    const itemWidth = () => `${100 / itemsPerPage}%`;
    const translatedPosition = `translate3d(${calculatePosition(
      activeItem
    )}%, 0px, 0px)`;

    return (
      <Box
        style={{
          WebkitTransform: translatedPosition,
          transform: translatedPosition,
        }}
        __css={{
          whiteSpace: "nowrap",
          transition,
          mx: -2,
        }}
        {...props}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            sx: {
              display: "inline-block",
              whiteSpace: "normal",
              width: itemWidth(),
              px: 2,
            },
          })
        )}
      </Box>
    );
  }
}

const delta = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => ({
  x: x1 - x2,
  y: y1 - y2,
});

export class DraggableSlides extends Component {
  state = {
    dragging: false,
    startPos: null,
    currentPos: null,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { current } = this.ref;
    current.addEventListener("mousedown", this.onDragStart);
    document.addEventListener("mousemove", this.onDragMove);
    document.addEventListener("mouseup", this.onDragEnd);
  }

  componentWillUnmount() {
    const { current } = this.ref;
    current.removeEventListener("mousedown", this.onDragStart);
    document.removeEventListener("mousemove", this.onDragMove);
    document.removeEventListener("mouseup", this.onDragEnd);
  }

  onDragStart = (evt) => {
    this.startX = this.ref.current
      ? new WebKitCSSMatrix(getComputedStyle(this.ref.current).transform)["m41"]
      : 0;
    const pos = this.getEventPosition(evt);
    this.setState({
      dragging: true,
      startPos: pos,
      currentPos: pos,
    });
  };

  onDragMove = (evt) => {
    if (!this.state.dragging) {
      return;
    }
    evt.preventDefault();

    this.setState({
      currentPos: this.getEventPosition(evt),
    });
  };

  onDragEnd = (evt) => {
    if (!this.state.dragging) {
      return;
    }
    evt.preventDefault();

    this.setState({
      dragging: false,
    });
  };

  getEventPosition = (evt) => {
    const findCandidate = (event) => {
      if (event.touches && event.touches.length) {
        return event.touches[0];
      }

      if (event.changedTouches && event.changedTouches.length) {
        return event.changedTouches[0];
      }

      return event;
    };
    const { pageX, pageY } = findCandidate(evt);

    return {
      x: pageX,
      y: pageY,
    };
  };

  render() {
    const { transition, children } = this.props;
    const { currentPos, startPos } = this.state;
    const dragDelta =
      currentPos && startPos ? delta(currentPos, startPos) : { x: 0, y: 0 };

    const translatedPosition = `translate3d(${
      this.startX + dragDelta.x
    }px, 0px, 0px)`;

    return (
      <Box
        style={{
          WebkitTransform: translatedPosition,
          transform: translatedPosition,
        }}
        __css={{
          whiteSpace: "nowrap",
          transition,
          mx: -2,
        }}
        ref={this.ref}
      >
        {children}
      </Box>
    );
  }
}

export const NativeSlides = ({ children, ...props }) => {
  const { itemsPerPage } = useContext(Context);
  const itemWidth = () => `${100 / itemsPerPage}%`;
  return (
    <Box
      __css={{
        whiteSpace: "nowrap",
        mx: -2,
        overflowX: "scroll",
        scrollSnapType: "x",
      }}
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          sx: {
            display: "inline-block",
            whiteSpace: "normal",
            width: itemWidth(),
            px: 2,
            scrollSnapAlign: "start",
          },
        })
      )}
    </Box>
  );
};

const NavComponent = ({ children, sx, ...props }) => (
  <Text
    as="div"
    role="button"
    sx={{
      display: "flex",
      alignItems: "center",
      fontSize: 4,
      fontWeight: "bold",
      cursor: "pointer",
      //px: 3,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Text>
);

export const Arrows = (props) => {
  const { slidePerPage, activeItem, slides, itemsPerPage } =
    useContext(Context);
  const slideStart = activeItem === 0;
  const slideEnd = itemsPerPage + activeItem >= slides;

  return (
    <Box {...props}>
      {!slideStart && (
        <NavComponent
          className="carousel__arrow carousel__arrow-left"
          onClick={slidePerPage.bind(null, -1)}
        >
          {"<"}
        </NavComponent>
      )}
      {!slideEnd && (
        <NavComponent
          className="carousel__arrow carousel__arrow-right"
          onClick={slidePerPage.bind(null, 1)}
        >
          {">"}
        </NavComponent>
      )}
    </Box>
  );
};

export const ArrowLeft = (props) => {
  const { slidePerPage, activeItem, slides, itemsPerPage } =
    useContext(Context);
  const slideStart = activeItem === 0;
  const slideEnd = itemsPerPage + activeItem >= slides;

  return (
    <Box {...props}>
      {/* {!slideStart && ( */}
      <NavComponent
        className="carousel__arrow carousel__arrow-left"
        onClick={slidePerPage.bind(null, -1)}
      >
        <ArrowPrev size={54} />
      </NavComponent>
      {/* )} */}
    </Box>
  );
};
export const ArrowRight = (props) => {
  const { slidePerPage, activeItem, slides, itemsPerPage } =
    useContext(Context);
  const slideStart = activeItem === 0;
  const slideEnd = itemsPerPage + activeItem >= slides;

  return (
    <Box {...props}>
      {/* {!slideEnd && ( */}
      <NavComponent
        className="carousel__arrow carousel__arrow-right"
        onClick={slidePerPage.bind(null, 1)}
      >
        <ArrowNext size={54} />
      </NavComponent>
      {/* )} */}
    </Box>
  );
};

import styled from "styled-components";
const StyledDot = styled.button`
  display: inline-block;
  padding: 0;
  border: none;
  outline: none;
  background-color: unset;
`;

export const Dots = ({
  activeColor = "rgba(237, 204, 146, 1)",
  color = "rgba(237, 204, 146, 0.2)",
  ...props
}) => {
  const { slides, itemsPerPage, activeItem, slideToItem } = useContext(Context);
  const dots = Math.ceil(slides / itemsPerPage);
  const clampedIndex = (index) => clamp(0, slides - itemsPerPage, index);

  const ButtonStyle = styled.span`
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 10px;
    background: ${color};
    border-radius: 9999px;

    &.active {
      background: ${activeColor};
    }
  `;

  return (
    <Box {...props}>
      {Array(dots)
        .fill(null)
        .map((item, index) => (
          <StyledDot
            key={`dots-${index}`}
            onClick={slideToItem.bind(null, clampedIndex(index * itemsPerPage))}
          >
            <ButtonStyle
              className={
                clampedIndex(index * itemsPerPage) === activeItem
                  ? "active"
                  : ""
              }
            />
          </StyledDot>
        ))}
    </Box>
  );
};

export const FixedDot = ({ index, children, ...props }) => {
  const { slideToItem } = useContext(Context);

  return (
    <Box onClick={slideToItem.bind(null, index)} {...props}>
      {children}
    </Box>
  );
};

export class Timer extends Component {
  static contextType = Context;

  static defaultProps = {
    interval: "4500",
  };

  constructor(props) {
    super(props);
    this.timer = null;
    this.increment = true;
  }

  componentDidMount() {
    this.timer = setInterval(this.slideOnInterval, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  slideOnInterval = () => {
    const { activeItem, slides, slidePerPage } = this.context;
    if (activeItem + 1 === slides) {
      this.increment = false;
    }

    if (activeItem === 0) {
      this.increment = true;
    }

    slidePerPage(this.increment ? 1 : -1);
  };

  render() {
    return null;
  }
}

export default Carousel;
