import React, { useEffect, useState } from "react";
import { Box, Text, Heading, Image } from "rebass/styled-components";
import Page from "../components/Page";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import Header from "../components/Header";
import About from "../components/About";
import Diensten from "../components/Diensten";
import CTA from "../components/CTA";
import NewsletterForm from "../components/NewsletterForm";
import { apiEndpoint, Client } from "../prismic-configuration";
import { useRouter } from "next/router";
import useUpdatePreviewRef from "../utils/useUpdatePreviewRef";

import Prismic from "@prismicio/client";
const Cookie = require("js-cookie");

const Index = ({ lang, doc, previewRef }) => {
  const { data } = doc;

  const [state, setState] = useState({
    showModal: false,
  });

  const showModal = () => {
    setState({ ...state, showModal: true });
  };

  const hideModal = () => {
    setState({ ...state, showModal: false });
  };

  const router = useRouter();
  useEffect(() => {
    const params = router.query;
    if (params.nieuwsbrief) {
      showModal();
    }
  }, []);

  // useUpdatePreviewRef(previewRef, doc.id);

  return (
    <Page
      title="MERLET | Vakmanschap in maatwerk, bedrijfskledij en promotietextiel"
      description="Maathemden en een ruim aanbod aan unieke bedrijfs- en promotiekleding door borduurwerk of textielbedrukking."
    >
      <Header currentPage="index" />
      {state.showModal && (
        <Modal
          py={[4, 5]}
          my="5%"
          width={["90%", 2 / 3, 2 / 3]}
          maxHeight="80%"
          maxWidth="90%"
          backgroundColor="#fbf4ea"
          minHeight={["175px", "260px", "475px"]}
          clickOutside={hideModal}
        >
          <Box sx={{ mx: "auto", width: "100%" }}>
            <Image
              src="https://merletimages.imgix.net/logo.png"
              height="35px"
              mb={3}
              width="35px"
              sx={{ ml: "50%", transform: "translateX(-50%)" }}
            />
          </Box>

          <Heading
            as="h3"
            variant="h3"
            textAlign="center"
            sx={{ color: "#d6b985", letterSpacing: "3.3px", mt: 2 }}
          >
            NIEUWSBRIEF
          </Heading>
          <Text textAlign="center" fontSize={(4, 6, 5)} mt={2}>
            Blijf je graag op de hoogte?
            <br />
            Schrijf je dan in voor onze nieuwsbrief.
          </Text>

          <NewsletterForm
            sx={{ maxWidth: "500px", mx: "auto", mt: "50px", mb: 3 }}
          />
        </Modal>
      )}
      <Box sx={{ pt: [3, 4, 115] }}></Box>
      <Hero slider={data.slider_slide} data={data}></Hero>

      {data.body.map((body, i) => {
        switch (body.slice_type) {
          case "services":
            return <Diensten data={body} key={`slice-${i}`} />;
          case "title_with_text":
            return <About data={body} key={`slice-${i}`} />;
          case "cta":
            return <CTA data={body} key={`slice-${i}`} />;
          default:
            return null;
        }
      })}

      <Footer lang={lang} />
    </Page>
  );
};

export async function getStaticProps({ previewData }) {
  const previewRef = previewData ? previewData.ref : null;
  const refOption = previewRef ? { ref: previewRef } : { ref: "" };

  const doc = (await Client().getSingle("home", refOption)) || {};
  return {
    props: {
      previewRef,
      doc,
    },
    revalidate: 60,
  };
}

// Index.getInitialProps = async (context) => {
//   console.log(context.previewData);
//   const home = await Prismic.client(apiEndpoint).getSingle("home", {});

//   return { doc: home };
// };

export default Index;
