import { Flex, Box } from 'rebass/styled-components';

const InputGroup = ({ children, ...props }) => (
  <Flex
    __css={{
      input: {
        borderRadius: 0,
      },
    }}
    {...props}
  >
    {children}
  </Flex>
);

const commonStyle = {
  bg: 'gray.300',
  p: 3,
  whiteSpace: 'nowrap',
};

InputGroup.prepend = ({ children, ...props }) => (
  <Box
    __css={{
      ...commonStyle,
      borderTopLeftRadius: 'default',
      borderBottomLeftRadius: 'default',
    }}
    {...props}
  >
    {children}
  </Box>
);

InputGroup.append = ({ children, ...props }) => (
  <Box
    __css={{
      ...commonStyle,
      borderTopRightRadius: 'default',
      borderBottomRightRadius: 'default',
    }}
    {...props}
  >
    {children}
  </Box>
);

export default InputGroup;
