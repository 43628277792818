import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { Box, Button, Image, Link } from "rebass/styled-components";
import styled from "styled-components";
import Carousel, {
  Slides,
  FixedDot,
  Dots,
  Timer,
  DraggableSlides,
} from "../components/Carousel";

const ImageBox = styled(Image)`
  padding: 0;
  width: 100%;
  vertical-align: middle;
`;

const Slider = ({ slides, showVideo, children, sx }) => (
  <Carousel itemsPerPage={1} width="100%" height="100%">
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        textAlign: "center",
      }}
      width="100%"
      height="100%"
    >
      <Slides>
        {slides.map((slide, i) => {
          return (
            <Box>
              <Box
                sx={{
                  backgroundImage:
                    !slide.video ||
                    (slide.video &&
                      !slide.video.url &&
                      `url(${slide.image.url})`),
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "white",
                  lineHeight: "1.11",
                  textAlign: "center",
                  position: "relative",
                }}
                minHeight={["400px", "400px", "500px"]}
                pt={["300px", "300px", "350px"]}
                pb="5"
              >
                {slide.title && (
                  <Box
                    fontSize={[6, 6, 7]}
                    mx="auto"
                    sx={{
                      width: "80%",
                      maxWidth: "700px",
                    }}
                  >
                    {slide.title}
                  </Box>
                )}

                {slide.button_text && (
                  <Box py={5}>
                    <Link href={slide.button_href}>
                      <Button
                        variant="buttons.primary"
                        sx={{ textTransform: "uppercase" }}
                      >
                        {slide.button_text}
                      </Button>
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}

        {/* <Box>
          <Box
            sx={{
              backgroundImage:
                'url(https://merletimages.imgix.net/merlet-maathemden-slider.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              lineHeight: '1.11',
              textAlign: 'center',
            }}
            minHeight={['400px', '400px', '500px']}
            pt={['300px', '300px', '350px']}
            pb="5"
          >
            <Box maxWidth="80%" fontSize={[6, 6, 7]} mx="auto">
              Een hemd uit onze collectie
              <br />
              of liever één op maat?
            </Box>
            <Box py={5}>
              <Link href="/maathemden">
                <Button variant="buttons.primary">MAATHEMDEN</Button>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundImage:
                'url(https://merletimages.imgix.net/projob-omslag-dark.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              lineHeight: '1.11',
              textAlign: 'center',
            }}
            minHeight={['400px', '400px', '500px']}
            pt={['300px', '300px', '350px']}
            pb="5"
          >
            <Box maxWidth="80%" fontSize={[6, 6, 7]} mx="auto">
              Ontdek ons topaanbod
              <br />
              voor professionals
            </Box>
            <Box py={5}>
              <Link href="/werkkleding">
                <Button variant="buttons.primary">WERKKLEDING</Button>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              backgroundImage:
                'url(https://merletimages.imgix.net/hector-j-rivas-nh-6-nsnq-y-vs-i-unsplash@2x-overlay.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              color: 'white',
              lineHeight: '1.11',
              textAlign: 'center',
            }}
            minHeight={['400px', '400px', '500px']}
            pt={['300px', '300px', '350px']}
            pb="5"
          >
            <Box maxWidth="80%" fontSize={[6, 6, 7]} mx="auto">
              De kracht van personalisatie
              <br />
              vervaardigd in ons eigen atelier
            </Box>
            <Box py={5}>
              <Link href="/atelier">
                <Button variant="buttons.primary">HET ATELIER</Button>
              </Link>
            </Box>
          </Box>
        </Box> */}
      </Slides>

      <Dots
        sx={{
          position: "absolute",
          left: "20px",
          right: 0,
          bottom: "20px",
          zIndex: 20,
        }}
        textAlign="center"
      />
      <Timer />
    </Box>
  </Carousel>
);

export default Slider;
