import { Box, Flex, Image, Link } from "rebass/styled-components";
import Container from "./Container";
import Maatwerk from "./svg/maatwerk";
import Bedrijfskledij from "./svg/bedrijfskledij";
import Borduurwerk from "./svg/borduurwerk";
import Textieldruk from "./svg/textieldruk";

const Diensten = ({ data, children, sx }) => (
  <Box
    sx={{
      bg: "lightYellow",
    }}
  >
    <Container>
      <Flex flexDirection={["column", "row", "row"]}>
        {data.items.map((item, i) => {
          return (
            <Box width={[1, 1 / 2, 1 / 4]} textAlign="center" py={4} px={2}>
              <Link href="/maathemden">
                <Box p={3}>
                  <Image src={item.icon.url} />
                </Box>

                <Box
                  variant="title"
                  color="dark"
                  p={3}
                  sx={{ textTransform: "uppercase" }}
                >
                  {item.title}
                </Box>
                <Box fontSize={"18px"}>{item.text}</Box>
              </Link>
            </Box>
          );
        })}
      </Flex>
    </Container>
  </Box>
);

export default Diensten;
