import { Box, Flex, Button, Link } from "rebass/styled-components";
import Arrow from "./svg/arrow";
import ContainerHero from "./ContainerHero";
import Slider from "./Slider";
import { RichText } from "prismic-reactjs";
import Modal from "./Modal";
import { useState } from "react";

const Hero = ({ data, children, sx }) => {
  const slider = data.slider_slide;
  const [showVideo, setShowVideo] = useState(false);
  const [previewVideoEnded, setPreviewVideoEnded] = useState(false);

  return (
    <>
      {data.full_video && data.full_video.url && showVideo && (
        <Modal
          py={[4, 5]}
          my="5%"
          width={["90%", 2 / 3, 2 / 3]}
          maxHeight="80%"
          maxWidth="90%"
          backgroundColor="#fbf4ea"
          minHeight={["175px", "260px", "475px"]}
          clickOutside={() => setShowVideo(false)}
        >
          <video style={{ width: "100%" }} autoPlay playsInline loop controls>
            <source src={data.full_video.url} type="video/mp4" />
          </video>
        </Modal>
      )}

      <Box sx={{ backgroundColor: "dark" }}>
        <ContainerHero>
          <Flex flexDirection={["column", "column", "row"]}>
            <Flex
              width={[1, 1, 2 / 3]}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ position: "relative" }}
            >
              {data.preview_video &&
              data.preview_video.url &&
              !previewVideoEnded ? (
                <>
                  <Box
                    sx={{
                      color: "white",
                      lineHeight: "1.11",
                      textAlign: "center",
                      width: "101%",
                      height: "100%",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      zIndex: 100,
                    }}
                    minHeight={["400px", "400px", "700px"]}
                    pt={["300px", "300px", "350px"]}
                    pb="5"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                        ".videoTag": {
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          minWidth: "100%",
                          minHeight: "100%",
                          width: "auto",
                          height: "auto",
                          transform: "translate(-50%, -50%)",
                        },
                      }}
                    >
                      <video
                        className="videoTag"
                        autoPlay
                        playsInline
                        muted
                        onEnded={() => {
                          setPreviewVideoEnded(true);
                        }}
                      >
                        <source src={data.preview_video.url} type="video/mp4" />
                      </video>
                    </Box>
                  </Box>

                  {data.full_video && data.full_video.url && (
                    <Box
                      py={5}
                      sx={{ position: "absolute", bottom: 5, zIndex: 200 }}
                    >
                      <Button
                        variant="buttons.primary"
                        sx={{ textTransform: "uppercase" }}
                        onClick={() => setShowVideo(true)}
                      >
                        Bekijk volledige video
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <div></div>
              )}

              <Slider slides={slider} />
            </Flex>
            <Flex
              width={[1, 1, 1 / 3]}
              flexDirection="column"
              sx={{
                backgroundColor: "duckEggBlue",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${data.block1_image.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  color: data.block1_text_dark ? "dark" : "white",
                  px: 5,
                  height: "50%",
                  a: {
                    color: data.block1_text_dark ? "dark" : "white",
                  },
                  position: "relative",
                  "&:after": {
                    backgroundColor: data.block1_text_dark
                      ? "rgba(255,255,255,0.3)"
                      : "rgba(0,0,0,0.5)",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    content: '""',
                    zIndex: 0,
                  },
                }}
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    h4: {
                      fontSize: 5,
                      fontWeight: "normal",
                      // m: 0,
                    },
                    // pt: 5,
                    // pb: 4,
                    lineHeight: "1.17",
                    h5: {
                      fontSize: 24,
                    },
                    p: {
                      mt: 0,
                      mb: 1,
                    },
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  {RichText.render(data.block1_text)}
                </Box>
                <Box sx={{ pb: 4, zIndex: 1, position: "relative" }}>
                  {data.block1_link.map((link, i) => {
                    return (
                      <Link
                        href={
                          link.block1_internal_href
                            ? link.block1_internal_href
                            : link.block1_external_href?.url
                        }
                      >
                        <Box
                          variant="goto"
                          sx={{
                            pb: 4,
                          }}
                        >
                          <Box
                            display="inline"
                            sx={{
                              mr: 2,
                              "&:hover": { mr: 3 },
                              transition: "all 0.2s",
                              textTransform: "uppercase",
                            }}
                          >
                            {link.block1_link_text}
                          </Box>
                          <Arrow />
                        </Box>
                      </Link>
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  px: 5,
                  backgroundImage: `url(${data.block2_image.url})`,
                  backgroundSize: "cover",
                  color: data.block2_text_dark ? "dark" : "white",
                  px: 5,
                  height: "50%",
                  a: {
                    color: data.block2_text_dark ? "dark" : "white",
                  },
                  position: "relative",
                  "&:after": {
                    backgroundColor: data.block2_text_dark
                      ? "rgba(255,255,255,0.5)"
                      : "rgba(0,0,0,0.5)",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    content: '""',
                    zIndex: 0,
                  },
                }}
                alignItems="center"
                justifyContent="center"
                flexGrow="1"
              >
                <Box
                  sx={{
                    pt: 5,
                    pb: 5,
                    lineHeight: "1.17",
                    h4: {
                      fontSize: 5,
                      fontWeight: "normal",
                      m: 0,
                    },
                    p: {
                      m: 0,
                    },
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  {RichText.render(data.block2_text)}
                </Box>
                <Box sx={{ pb: 4, zIndex: 1, position: "relative" }}>
                  {data.block2_link.map((link, i) => {
                    return (
                      <Link
                        href={
                          link.block2_internal_href
                            ? link.block2_internal_href
                            : link.block2_external_href?.url
                        }
                      >
                        <Box
                          variant="goto"
                          sx={{
                            pb: 4,
                          }}
                        >
                          <Box
                            display="inline"
                            sx={{
                              mr: 2,
                              "&:hover": { mr: 3 },
                              transition: "all 0.2s",
                              textTransform: "uppercase",
                            }}
                          >
                            {link.block2_link_text}
                          </Box>
                          <Arrow />
                        </Box>
                      </Link>
                    );
                  })}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </ContainerHero>
      </Box>
    </>
  );
};

export default Hero;
