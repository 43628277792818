import React, { Component } from 'react';
import { Box } from 'rebass/styled-components';

const fixedPos = {
  position: 'fixed',
  top: 0,
  left: 0,
};

class Modal extends Component {
  static defaultProps = { my: '5%', clickOutside: () => {} };

  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  isOutside = evt => {
    const { current } = this.modal;
    const { clickOutside } = this.props;

    if (!current.contains(evt.target)) {
      clickOutside();
    }
  };

  render() {
    const { my, children, ...props } = this.props;
    return (
      <Box
        sx={{
          ...fixedPos,
          height: '100%',
          width: '100%',
          bg: 'rgba(0,0,0,0.4)',
          zIndex:9999,
        }}
        onClick={this.isOutside}
      >
        <Box
          sx={{
            maxHeight: `calc(100vh - ${my})`,
            overflowY: 'auto',
          }}
        >
          <Box
            my={my}
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              bg: 'white',
              boxShadow: 'lg',
              padding: 4,
              borderRadius: 'default',
            }}
            ref={this.modal}
            {...props}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Modal;