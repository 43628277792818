import {Box} from 'rebass/styled-components';
function ArrowRight({size}) {
  return (
    <Box
      as="svg"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 23 9"
      width={size}
      height={size}
      px={3}
      /*style="enable-background:new 0 0 23 9;"*/
      sx={{
        stroke: 'dark',
        '&:hover': {
          stroke: 'gold',
        },
      }}
    >
      <title>Merlet arrow</title>

      <line
        x1="18.48"
        y1="8.4"
        x2="22.52"
        y2="4.35"
        fill="none"
        strokeWidth="1"
      />
      <line
        x1="18.48"
        y1="0.35"
        x2="22.52"
        y2="4.4"
        fill="none"
        strokeWidth="1"
      />
      <line x1="0" y1="4.38" x2="23" y2="4.38" fill="none" />
    </Box>
  );
}
export default ArrowRight;
