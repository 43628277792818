import { Box, Link, Image } from "rebass/styled-components";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

const ImageBox = styled(Image)`
  padding: 0;
  width: 100%;
  vertical-align: middle;
`;

const About = ({ data, column = 1, children, sx }) => {
  console.log(data)
  return (
  <Box
    px="3"
    sx={{
      bg: "lightYellow",
      a: {
        color: "gold",
      },
    }}
    id="about"
  >
    {data && data.primary && (data.primary.text || data.primary.title) && (
      <Box
        maxWidth="792px"
        variant="intro"
        sx={{ py: 5, pb: data.primary.border_bottom ? 5 : 0 }}
      >
        {data.primary.title}

        {data.primary.text.length > 0 && (
          <Box
            sx={{
              py: 5,
              pb: data.primary.border_bottom ? 5 : 0,
              textAlign: "left",
              p: { fontSize: "18px", m: 0, mb: 4 },
              h6: {
                fontFamily: "Raleway",
                pb: "4px",
                fontSize: "11px",
                fontWeight: "bold",
                letterSpacing: "3.3px",
                textTransform: "uppercase",
                m: 0,
              },
            }}
          >
            <Box py={2} sx={{ columnCount: [1, column] }}>
              {RichText.render(data.primary.text)}
            </Box>
          </Box>
        )}
      </Box>
    )}
    {children}
    {data.primary.image && data.primary.image.url && data.primary.image_href && (
      <Box
        maxWidth="300px"
        mx="auto"
        sx={{
          pb: 5,
          pt: 4,
          //position: 'relative',
        }}
      >
        <Link href={data.primary.image_href.url} target="_blank">
          <ImageBox
            src={data.primary.image.url}
            alt={data.primary.image.alt}
            sx={{
              cursor: "pointer",
            }}
          ></ImageBox>
        </Link>
      </Box>
    )}
    {data.primary.image && data.primary.image.url && !data.primary.image_href && (
      <Box
        maxWidth="700px"
        maxHeight="500px"
        mx="auto"
        sx={{
          pb: 5,
          pt: 4,
          //position: 'relative',
        }}
      >
        
          <ImageBox
            src={data.primary.image.url}
            alt={data.primary.image.alt}
            sx={{
              cursor: "pointer",
            }}
          ></ImageBox>
      </Box>
    )}
    {data.primary.border_bottom && (
      <Box
        sx={{
          opacity: "0.2",
          width: "100%",
          height: "0px",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gold",
        }}
      ></Box>
    )}
  </Box>
)};

export default About;
