import {Box} from 'rebass/styled-components';
function ArrowLeft({size}) {
  return (
    <Box
      as="svg"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 23 9"
      width={size}
      height={size}
      px={3}
      /*style="enable-background:new 0 0 23 9;"*/
      sx={{
        stroke: 'dark',
        '&:hover': {
          stroke: 'gold',
        },
      }}
    >
      <title>Merlet arrow</title>

      <line
        x1="4.52"
        y1="0.35"
        x2="0.48"
        y2="4.4"
        fill="none"
        strokeWidth="1"
      />
      <line
        x1="4.52"
        y1="8.4"
        x2="0.48"
        y2="4.35"
        fill="none"
        strokeWidth="1"
      />
      <line x1="23" y1="4.4" x2="0" y2="4.4" fill="none" />
    </Box>
  );
}
export default ArrowLeft;
