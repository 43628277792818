import { Box, Link, Flex, Button } from "rebass/styled-components";
import Container from "./Container";
import Maatwerk from "./svg/maatwerk";
import Bedrijfskledij from "./svg/bedrijfskledij";
import Borduurwerk from "./svg/borduurwerk";
import Textieldruk from "./svg/textieldruk";

const CTA = ({ data, children, sx }) => {
  return (
    <Box
      sx={{
        bg: "duckEggBlue",
        textAlign: "center",
      }}
      py="53px"
    >
      <Box
        min-height="96px"
        //maxWidth="792px"
        mx="auto"
        px="3"
        mb="4"
        sx={{ color: "dark", fontSize: "5", lineHeight: "1.17" }}
      >
        {data && data.primary && data.primary.title}
      </Box>
      <Box
        min-height="96px"
        maxWidth="900px"
        mx="auto"
        px="3"
        mb="4"
        sx={{ color: "dark", fontSize: "5", lineHeight: "1.17" }}
      >
        {data && data.primary && data.primary.subtitle}
      </Box>
      <Box>
        {data &&
          data.primary &&
          data.primary.button_text &&
          (data.primary.button_internal_href ||
            data.primary.button_external_href) && (
            <Link href="/contact">
              <Button
                variant="buttons.primary"
                sx={{ textTransform: "uppercase" }}
              >
                {data && data.primary && data.primary.button_text}
              </Button>
            </Link>
          )}
      </Box>
    </Box>
  );
};

export default CTA;
