import { Flex, Button, Text } from "rebass";
import React, { useState } from "react";
import { Input } from "../components/Forms";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fetch from "node-fetch";
import { faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const NewsletterForm = ({ sx }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [state, setState] = useState({
    email: "",
  });

  const recaptchaRef = React.createRef();

  const handleChange = (event) => {
    // event.preventDefault();
    const { target } = event;
    const { value } = target;
    const { name } = target;

    setState({ ...state, [name]: value });

    setFormSuccess(false);
    setFormSubmitted(false);
  };

  const sendNewsletterForm = async () => {
    if (state.email) {
      // @ts-ignore
      const token = await recaptchaRef.current.executeAsync();

      setFormSubmitted(true);

      console.log("herewerare");

      await fetch("/api/send-newsletter-mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...state,
          responseToken: token,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data && data.success) {
            setFormSuccess(true);
          } else {
            // setFormFailed(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Flex sx={{ ...sx, flexDirection: "column" }}>
      {/* <Flex sx={{ justifyContent: "space-between" }}>
        <Input
          id="name"
          name="name"
          variant="footer"
          type="text"
          placeholder="Naam"
          onChange={handleChange}
          value={state.name}
          sx={{ width: "49%" }}
        /> */}
      <Input
        id="email"
        name="email"
        variant="footer"
        type="email"
        placeholder="E-mailadres"
        onChange={handleChange}
        value={state.email}
        // sx={{ width: "49%" }}
      />
      {/* </Flex> */}

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Lc4594UAAAAAJWc8agdZChxVXKd1fcBnStmOVFT"
        size="invisible"
      />
      <Button
        // disabled={formSubmitted || formSuccess}
        onClick={(e) => {
          e.preventDefault();
          sendNewsletterForm();
        }}
        variant="primary"
        sx={{
          alignSelf: "center",
          color: "#1a1b22",
          bg: "#d6b985",
          transition: "all 0.5sy",
          "&:hover": {
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            cursor: "pointer",
          },
          py: 3,
          px: 4,
          mt: "12px",
          fontFamily: "Raleway",
          fontSize: 0,
          fontWeight: "bold",
          letterSpacing: "3.3px",
          borderRadius: "0",
          // "&:disabled": {
          //   backgroundColor: "rgba(252,41,1,0.7)",
          //   cursor: "default",
          // },
        }}
      >
        {/* <Box sx={{}}> */}
        {formSuccess ? (
          <FontAwesomeIcon icon={faCheck} color="#0E2434" />
        ) : formSubmitted ? (
          <FontAwesomeIcon icon={faCircleNotch} spin color="#0E2434" />
        ) : (
          <Text>Schrijf me in</Text>
        )}
        {/* </Box> */}
        {/* <ArrowRight size={35} /> */}
      </Button>
    </Flex>
  );
};

export default NewsletterForm;
