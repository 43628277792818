import React, { forwardRef } from 'react';
import { Box } from 'rebass/styled-components';

export default forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="label"
    variant="label"
    __css={{
      display: 'block',
      width: '100%',
      mb: 1,
    }}
    {...props}
  />
));
